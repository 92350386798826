<template lang="pug">
	#timelineHorizontal
		h2 Linha do tempo
		.galeria
			.controls
				button.left(@click="changePage(ativo - 1)" :disabled="ativo == 0")
					SvgIcon(data="@svgs/seta-ippuc.svg")
				button.right(@click="changePage(ativo + 1)" :disabled="ativo == paginacao - 1")
					SvgIcon(data="@svgs/seta-ippuc.svg")
			.linhaDoTempo
				ul.trilho(ref="trilho")
					li.quadro(v-for="materias, index in quadros", ref="quadro")
						.first(@click="$emit('setContent', materias[0])")
							Imagem(:contents="materias[0].image").img
							div.info
								h3 {{ materias[0].content.title.pt }}
								p {{ materias[0].content.description.pt }}
								span Ver mais
						.second(@click="$emit('setContent', materias[1])", v-if="materias[1]")
							Imagem(:contents="materias[1].image").img
							div.info
								h3 {{ materias[1].content.title.pt }}
								p {{ materias[1].content.description.pt }}
								span Ver mais

		ul.paginacao
			li.atual(v-for="pagina, index in paginacao")
				button(:class="{'ativo': index == ativo}" @click="changePage(index)")
					span {{pagina}}
</template>

<script>
import { components, props } from '@/mixins/components'

export default {
  name: 'component-timeline-horizontal',
  props,
  components,
  data () {
    return {
      ativo: 0
    }
  },
  computed: {
    paginacao () {
      return Math.ceil(this.contents.length / 2)
    },
    quadroSize () {
      if (this.$refs.quadro) { return parseFloat(window.getComputedStyle(this.$refs.quadro[0]).width) }
      return 0
    },
    quadros () {
      const quadros = []
      for (let i = 0; i < this.contents.length; i = i + 2) {
        const tempQuadros = []
        tempQuadros.push(this.contents[i])
        if (i < this.contents.length - 1) { tempQuadros.push(this.contents[i + 1]) }

        quadros.push(tempQuadros)
      }
      return quadros
    }
  },
  mounted () {
    if (this.$refs && this.$refs.trilho) { this.$refs.trilho.style.width = (this.quadroSize * (this.paginacao - 1)) + 'px' }
  },
  methods: {
    changePage (index) {
      this.ativo = index
      this.$refs.trilho.style.transform = `translateX(-${this.quadroSize * this.ativo}px)`
    }
  }
}
</script>

<style lang="stylus" scoped src="./TimelineHorizontal.styl"></style>
